@import "@/styles/index.scss";

.backdrop {
  z-index: 99999;
  position: fixed;
  inset: 0px;

  .content {
    -webkit-overflow-scrolling: touch;
    position: absolute;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 4px;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.3s;

    &.ready {
      opacity: 1;
    }
  }

  &.arrowTop {
    .content {
      &:before {
        position: absolute;
        content: '';
        top: -11px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid white;
    }
    }
  }
}

.loader {
  position: absolute;
  top: 7px;
  right: 5px;
  z-index: 10;
}