@import "@/styles/index.scss";

.banner {
    margin-bottom: 5rem;
}

.breadcrumbs {
    padding: 0;
}

.pageProductsList {
    display: flex;
    padding-top: 8px;
    @include mobileParameter(padding-top, 0);

    .content {
        flex: 1;
        @include pageContentBottom();
        overflow: hidden;
    }

    .side {
        @include pageContentBottom();
        @include productsSidePanel();

        &.stickyScrollable {
            position: sticky;
            top: $stickyMenuHeight + 8px;
            max-height: calc(100vh - $stickyMenuHeight - 16px);
            align-self: flex-start;
            margin-bottom: 64px;
        }

        &.stickToTop {
            position: sticky;
            top: $stickyMenuHeight;
            align-self: flex-start;
        }

        &.stickToTopRelative {
            position: relative;
            top: 0;
            align-self: flex-start;
        }

        &.stickToBottom {
            position: sticky;
            bottom: 0;
            align-self: flex-end;
        }

        .search {
            margin-top: 30px;
            margin-bottom: 2rem;
            border: 1px solid rgba(175, 175, 175, 0.493);

            input {
                background-color: transparent;
                border-radius: unset;
            }
        }

        .scrollableArea {
            padding-right: 12px;
            max-height: calc(100vh - $stickyMenuHeight - 16px);
            min-height: 0;
            overflow-x: hidden;
            overflow-y: auto;
            margin-left: -100%;
            padding-left: 100%;
            z-index: 1;
            position: relative;

            @include scrollable(4px, true);

            &::-webkit-scrollbar {
                background-color: rgba(233, 233, 233, 0.3);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #d9d9d9;
                border-radius: 8px;
                cursor: pointer;

                &:hover {
                    background-color: #c9c9c9;
                }
            }
        }

        .verticalTrack {
            background-color: rgba(233, 233, 233, 0.3);
        }

        .horizontalTrack {
            display: none;
        }
    }



    .list {
        @include productsListContainer();
        //@include mediaParameter(margin-bottom, 40px, 40px, 24px, 24px);
    }

    .wrapItem {
        @include productWrapItem();

        &.dirty {
            @include draftItem();
        }
    }

    .wrapHeader {
        .resetPanel {
            margin-bottom: 20px;
        }
    }

    .categoryNameWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;

        .title {}
    }

    .wrapButtons {
        display: flex;
        gap: 12px;

        &>* {
            flex: 1;
        }

        &.mobileMargin {
            margin-bottom: 16px;
        }
    }

    .sortby {
        margin-left: 1.5rem;
        height: 32px;
        @include setProperty(font-size, --font-size-sm, true);
        align-self: flex-start;

        &.pageSearch {
            margin-top: 0;
        }
    }
}
