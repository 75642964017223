@import "@/styles/index.scss";

.PageTitle {
    font-family: var(--font-inter);
    font-weight: 700;
    letter-spacing: 0.25px;
    @include mediaParameter(font-size, 32px, 26px, 26px, 1rem);
    @include mediaParameter(line-height, 39px, 31px, 31px, 20px);
    @include setProperty(color, --color-black);
}
