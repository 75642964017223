@import "@/styles/index.scss";

.ProductsFound {
    color: #191C1F;
    @include mobileParameter(display, flex);
    @include mobileParameter(align-items, flex-end);

    .count {
        //font-weight: 700;
    }
}
