@import "@/styles/index.scss";


.Select {
  position: relative;
  white-space: nowrap;

  .value {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.center {
      justify-content: center;
    }
  }

  [data-arrow="icon-arrow"] {
    transform: rotate(0deg);
    transition: transform 0.3s;
  }

  &.show {
    [data-arrow="icon-arrow"] {
      transform: rotate(180deg);
    }
  }
}

.options {
  max-height: 200px;
  @include scrollable();
  border-radius: 4px;
  box-shadow: 0px 1.181249976158142px 6.474999904632568px 0px rgba(0, 0, 0, 0.02), 0px 5.400000095367432px 18.200000762939453px 0px rgba(0, 0, 0, 0.04), 0px 13.668749809265137px 48.82500076293945px 0px rgba(0, 0, 0, 0.05), 0px 27px 112px 0px rgba(0, 0, 0, 0.07);

  &>div {
    padding: 8px 12px;
    border-top: 1px solid #E6EAED;
    cursor: pointer;

    @include hovered();

    &:hover {
      @include setProperty(background-color, --light-item-hovered);
    }
  }
}