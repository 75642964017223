@import "@/styles/index.scss";

.content {
    display: flex;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    overflow: auto;
    padding: 24px;
}

@mixin quantity {
    min-width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #ED5C4C;
    padding: 4px;
    color: #FFF;
    @include setProperty(font-size, --font-size-sm);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.MobileCategoryFilters {
    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        height: 32px;
        border-radius: 4px;
        background-color: #f0f2f5;
        @include setProperty(font-size, --font-size-sm);
        font-weight: 700;
        letter-spacing: 0.06px;

        .quantity {
            @include quantity;
        }
    }
}


.MobileStoreFilters {
    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        height: 32px;
        border-radius: 4px;
        background-color: #E6EAED;
        @include setProperty(font-size, --font-size-sm);
        font-weight: 700;
        letter-spacing: 0.06px;

        .quantity {
            @include quantity;
        }
    }

    .modalTitle {
        position: relative;
        top: -18px;
        text-align: center;
        font-size: 1rem;

        .quantity {
            color: #828B94;
        }
    }



    .property {
        display: flex;
        min-height: 40px;

        .wrap {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .label {
                &.bold {
                    font-weight: 700;
                }
            }

            .value {
                @include setProperty(font-size, --font-size-sm);
                color: #828B94;
                letter-spacing: 0.06px;
            }
        }

        .wrapArrow {
            display: flex;
            align-items: center;
        }
    }

    .div {
        margin: 12px 0;
        border-bottom: 1px solid #E6EAED;
    }

    .checkList {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.div {
    margin: 12px 0;
    border-bottom: 1px solid #E6EAED;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.buttonReset {
    margin: 1rem 0;
    flex-shrink: 1;
    width: min-content;
    white-space: normal;
}

.buttonShow {
    width: max-content;
    flex-grow: 1;
    padding-left: 4px;
    padding-right: 4px;
}


.MobileModalCategoryFilters,
.MobileModalStoreFilters {
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;

    &.loading {
        pointer-events: none;
        opacity: 0.7;
    }

    .modalTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-family: var(--font-inter);

        .quantity {
            color: #828B94;
        }
    }

    .wrapContent,
    .wrapContentList {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .wrapContent {
        overflow-y: auto;
        // max-height: 70vh;
    }

    .selectedFilters {
        display: flex;
        padding: 12px 0;
        flex-direction: column;
        gap: 8px;

        .selectedFiltersTitle {
            @include setProperty(color, --color-black);
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
        }
    }

    .list {
        max-height: calc(100vh - 200px);
        flex: 1;
        @include hiddenScroll();
    }

    .wrapContentList {
        overflow: hidden;

        .checkList {
            overflow-y: auto;
        }
    }

    .property {
        display: flex;
        min-height: 40px;

        .wrap {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .label {
                &.bold {
                    font-weight: 700;
                }
            }

            .value {
                @include setProperty(font-size, --font-size-sm);
                color: #828B94;
                letter-spacing: 0.06px;

                &.valueSelected {
                    @include setProperty(color, --color-black);
                }
            }
        }

        .wrapArrow {
            display: flex;
            align-items: center;
        }
    }

    .div {
        margin: 12px 0;
        border-bottom: 1px solid #E6EAED;
    }

    .checkList {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .count {
            @include setProperty(color, --color-secondary);
        }
    }
}

.buttonReset {
    margin: 1rem 0;
}

.buttonApply {
    margin-top: 1rem;
}

.MobileFilterToggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
}

.PanelSelectedFilters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .resetSelectedButton {
        max-width: calc(50% - 8px);
    }

    .resetSelectedButtonText {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline;
    }
}
