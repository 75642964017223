@import "@/styles/index.scss";

.ImageWithBackground {
    position: relative;

    &>* {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:first-child {
            filter: opacity(0.1) grayscale(1);
        }
    }

    &.disabledBackground {
        &>* {
            &:first-child {
                filter: unset;
            }
        }
    }
}