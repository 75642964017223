@import "@/styles/index.scss";

.form {
    text-align: center;
    min-width: 336px;
    @include mobileParameter(min-width, unset);
    max-width: 336px;

    @include mobileParameter(width, 100%);
    @include mobileParameter(min-width, 100%);
    @include mobileParameter(max-width, 100vw);

    .bottomMarginLarge {
        margin-bottom: 20px;
    }

    .bottomMarginMiddle {
        margin-bottom: 12px;
    }

    .bottomMarginSmall {
        margin-bottom: 8px;
    }

    .icons {
        margin-bottom: 8px;

        svg {
            width: 64px;
            height: 64px;
        }
    }

    .title_desktop {
        @include fontHeadlineH5;
        @include setProperty(color, --color-black);
    }

    .title {
        margin: 16px 0;
        text-align: center;
        @include fontBodySmallRegular;
        @include setProperty(color, --text-color-bold);
    }

    .recoveryTitle, .emailSentTitle {
        margin: 8px 0 12px 0;
        text-align: center;
        font-size: 1rem;
        line-height: 24px;
        @include setProperty(color, --color-secondary);
    }

    .footer {
        margin-top: 5px;

        .wrap {
            display: flex;
            //justify-content: space-between;
            justify-content: flex-end;
            align-items: flex-start;
            //min-height: 52px;
            padding-bottom: 16px;

            .forgot {
                @include setProperty(font-size, --font-size-sm);
                @include setProperty(line-height, --font-size-sm);
            }
        }
    }

    button[type=submit] {
        width: 100%;
    }
}

.basicWrap {
    text-align: left;

    .inputPrompt {
        margin-top: 8px;
        font-weight: 500;
        @include setProperty(font-size, --font-size-sm);
        line-height: 16px;
        letter-spacing: 0.5px;
        @include setProperty(color, --color-secondary);
    }


}

.charsCounterWrapper {
    position: relative;

    .charsCounter {
        position: absolute;
        right: 12px;
        bottom: 0;
        font-weight: 500;
        @include setProperty(font-size, --font-size-sm);
        line-height: 16px;
        letter-spacing: 0.5px;
        @include setProperty(color, --color-secondary);

        &.charsCounterTextarea {
        }

        &.charsCounterDropdown {
            bottom: 25px;
        }

        &.charsCounterInLabel {
            bottom: -8px;
        }

        &.charsCounterEditor {
            bottom: 30px;
            right: 24px;
        }
    }
}

.basicGroup {
    display: flex;
}

.basicWrapInput {
    position: relative;
    flex: 1;
    width: 100%;

    @include fontBodyMediumRegular;

    @include setProperty(border-radius, --input-border-radius);
    @include setProperty(background-color, --input-background-color);
    @include setProperty(box-shadow, --input-shadow);
    @include setProperty(transition, --input-transition);

    &.textarea {
        @include setProperty(min-height, --input-height);
    }

    &.basicIsEmpty {
        @include setProperty(background-color, --input-empty-background-color);

        input,
        .input,
        textarea {
            @include setProperty(outline, --input-empty-outline);
        }
    }

    input,
    .input,
    textarea {
        display: block;
        padding: 0 16px;
        border: 0;
        outline: 0;
        @include setProperty(min-height, --input-height);
        @include setProperty(background-color, --input-background-color);
        width: calc(100% - 2px);
        @include setProperty(border, --input-border);
        @include setProperty(outline, --input-outline);
        @include setProperty(border-radius, --input-border-radius);
        @include setProperty(color, --input-color);
        @include setProperty(transition, --input-transition);
        letter-spacing: 0.25px;
        font-size: 1rem;
        line-height: 1;
        resize: vertical;

        &:focus {
            @include setProperty(outline-color, --input-outline-color-active);
            @include setProperty(background-color, --input-focused-background-color);
        }

        &::placeholder {
            @include setProperty(color, --input-placeholder-color);
        }

        &:disabled, .disabled {
            outline: none;
            @include setProperty(background-color, --input-disabled-background-color, true);
        }

        &.error {
            @include setProperty(outline-color, --input-outline-color-error, true);
            @include setProperty(background-color, --input-error-background-color, true);
        }
    }

    textarea {
        padding: 1rem;
    }

    &.basicIsEmpty {

        input,
        .input,
        textarea {
            @include setProperty(color, --input-placeholder-color);
            @include setProperty(background-color, --input-empty-background-color);
        }
    }

    &.focus {
        @include setProperty(outline-color, --input-outline-color-active);
        @include setProperty(background-color, --input-focused-background-color);
    }

    &.placeholder {
        @include setProperty(color, --input-placeholder-color);
    }

    &:disabled,
    &.disabled {
        //opacity: 0.6;
    }
}

textarea.basicInput {
    height: 120px;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    @include setProperty(border-radius, --input-border-radius);
    flex-grow: 0;
    outline: none;
    font-size: 1rem;
    line-height: 140%;
    letter-spacing: 0.25px;
    @include setProperty(color, --text-color-bold);
    background-color: transparent;
    padding: 0 1em;
    font-family: inherit;

    outline: 1px solid transparent;
    transition: all 0.3s;

    &:focus {
        @include setProperty(outline-color, --color-primary);
    }

    &::placeholder {
        @include setProperty(color, --input-placeholder-color);
    }
}

input.basicErrorInput,
textarea.basicErrorInput {
    @include setProperty(color, --field-color-error);
}

.basicLabel {
    // position: absolute;
    // left: 0px;
    // top: -20px;
    margin-bottom: 8px;
    @include fontBodySmallRegular;
    //font-size: 12px;
    //line-height: 1;
    //letter-spacing: 0.005em;
    text-align: left;
    pointer-events: none;
    @include setProperty(color, --field-label-color);
    // transition: left 0.2s, top 0.2s;
}

.basicAsPlaceholder {
    // position: absolute;
    // left: 24px;
    // top: 20px;
    // @include fontSize(19);
}

.basicHint {
    margin-top: 4px;
    font-weight: 500;
    @include setProperty(font-size, --font-size-sm);
    letter-spacing: 0.5px;
    color: #828B94;
    min-height: 16px;
    margin-bottom: 4px;
    text-align: left;
}

.basicHintError {
    @include setProperty(color, --field-color-error);
    text-align: left;
}

.basicError {
    margin-top: 8px;
    @include setProperty(font-size, --font-size-sm);
    letter-spacing: 0.25px;
    @include setProperty(color, --field-color-error);
    text-align: left;
    min-height: 16px;
    margin-bottom: 8px;
    opacity: 0;

    &.show {
        opacity: 1;
    }
}

.hiddenSubmit {
    position: absolute;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.row {
    display: flex;
    @include mobileParameter(display, unset);

    &>* {
        flex: 1;
        margin-right: 1rem;
        @include mobileParameter(margin-right, 0, true);

        &:last-child {
            margin-right: 0;
        }
    }

    &.large {
        &>* {
            margin-right: 2rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
