@import "@/styles/index.scss";

.CustomScroll {
    @include setProperty(--track-color, --scroll-track-color);
    @include setProperty(--thumb-color-hovered, --scroll-thumb-color-hovered);
    @include setProperty(--trumb-color, --scroll-trumb-color);
    --track-border-radius: 8px;
    --track-width: 8px;
    --track-width-hover: 10px;

    position: relative;
    overflow: hidden;

    &.thin {
        --track-border-radius: 4px;
        --track-width: 4px;
        --track-width-hover: 6px;
    }

    .content {
        overflow: auto;
        overscroll-behavior: none;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: 0px;
        scrollbar-width: none; /* Firefox 64 */

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
    }

    .verticalTrack {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: var(--track-width);
        background-color: var(--track-color);
        transition: all 0.2s ease;

        &:hover {
            width: var(--track-width-hover);
        }

        .verticalTrumb {
            background-color: var(--trumb-color);
            position: absolute;
            width: 100%;
            border-radius: var(--track-border-radius);
            height: 0;
            cursor: pointer;

            &:hover {
                background-color: var(--thumb-color-hovered);
            }
        }
    }

    .horizontalTrack {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: var(--track-width);
        background-color: var(--track-color);
        transition: all 0.2s ease;

        &:hover {
            height: var(--track-width-hover);
        }

        .horizontalTrumb {
            background-color: var(--trumb-color);
            position: absolute;
            width: 0;
            height: 100%;
            border-radius: var(--track-border-radius);
            cursor: pointer;

            &:hover {
                background-color: var(--thumb-color-hovered);
            }
        }
    }

    &.showOnlyOnHover {
        .verticalTrack {
            opacity: 0;
            .verticalTrumb {
                opacity: 0;
            }
        }

        .horizontalTrack {
            opacity: 0;
            .horizontalTrumb {
                opacity: 0;
            }
        }

        &:hover {
            .verticalTrack {
                opacity: 1;
                .verticalTrumb {
                    opacity: 1;
                }
            }

            .horizontalTrack {
                opacity: 1;
                .horizontalTrumb {
                    opacity: 1;
                }
            }
        }
    }
}
