@import "@/styles/index.scss";

.SubCategories {

    ul {
        margin: 0;
        list-style-type: none;
        padding-inline-start: 0;
    }

    li {}

    .item {
        padding: 6px 3px;
        @include labelFontSize;

        .name {
            @include ellipsisMultiLines(1);
        }

        .space {
            display: block;
            flex: 1;
        }

        a {
            display: flex;
            align-items: center;
            @include setProperty(color, --color-black);

            &:hover {
                @include setProperty(color, --color-black);
                text-decoration: underline;
                opacity: 1;
            }
        }

        .itemIcon {
            display: flex;
            align-items: center;

            & > span {
                position: relative;
                top: 1px;
            }
        }
    }

    .parentItem {
        display: flex;
        align-items: center;
        font-weight: 700;

        img {
            margin-right: 8px;
        }

        a {
            text-decoration: unset !important;
        }

        transition: opacity 0.3s;

        &:hover {
            @include setProperty(opacity, --opacity-value);
        }
    }
}
