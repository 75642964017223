@import "@/styles/index.scss";

.NativeSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.01;
  z-index: 2;
}