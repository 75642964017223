@import "@/styles/index.scss";

.BlockMaxWidth {
    width: 100%;
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 20px;

    @include mobileParameter(padding, 0 12px);
}
