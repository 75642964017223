@import "@/styles/index.scss";

.CardProduct {
  text-align: left;
  transition: filter 0.1s;
  filter: grayscale(0) opacity(1);
  position: relative;

  .link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-width: 50px;
    min-height: 50px;
  }

  &.dirty {
    filter: grayscale(1) opacity(0.5);
  }

  &.highlightOnHover {
    &:hover {
      cursor: pointer;
      //@include setProperty(opacity, --opacity-value);

      .image {
        transform: scale(1.1);
      }
    }
  }

  &.outOfStock {
    opacity: 0.3;
  }

  .imageContainer {
    position: relative;
    width: 100%;
    height: 196px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f2f2f7;

    .image {
      height: 100%;
      width: 100%;
      transition: transform 200ms ease-in-out;

      &>* {
        height: 100%;
        width: 100%;
      }
    }

    &.small {
      height: 196px;
    }
  }

  .block1 {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    height: 22px;

    .final {
      font-family: var(--font-inter);
      font-weight: 700;
      font-size: 18px;
      @include mobileParameter(font-size, 1rem);
      color: #191C1F;
    }

    .old {
      text-decoration-line: line-through;
      color: #828B94;
      @include labelFontSize;
      @include mobileParameter(font-size, 12px);
      @include mobileParameter(line-height, 1rem);
    }

    .discondPercent {
      display: flex;
      height: 18px;
      background: #F8655C;
      border-radius: 3px;
      padding: 0 4px;

      @include setProperty(font-size, --font-size-sm);
      line-height: 1.2rem;
      letter-spacing: 0.005em;
      color: #FFFFFF;
    }
  }

  .block2 {
    display: flex;
    // align-items: baseline;
    margin-top: 6px;
    min-height: 20px;

    .rating {
      display: inline-flex;
      align-items: center;

      .value {
        font-weight: 500;
        @include setProperty(font-size, --font-size-sm);
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #191C1F;
        margin-left: 2px;
      }
    }

    .comment {
      display: inline-flex;
      align-items: center;
      margin-left: 9px;

      .value {
        font-weight: 500;
        @include setProperty(font-size, --font-size-sm);
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #828B94;
        margin-left: 2px;
      }
    }

    .favorites {
      display: inline-flex;
      align-items: center;
      gap: 2px;
      position: relative;

      .value {
        font-weight: 400;
        @include setProperty(font-size, --font-size-sm);
        line-height: 16px;
        color: #828B94;
        margin-right: 20px;

        &.hidden {
          opacity: 0 !important;
        }
      }
    }

    .like {
      position: absolute;
      background: transparent;
      top: -15px;
      right: -13px;
    }

    .like:active {
      background-color: transparent !important;
      //transform: translate(0px, -2px);
    }

    .like {
      transform: scale(1);
      transition: transform 0.1s;
    }

    .like:hover {
      transform: scale(1.1);
    }
  }

  .title {
    font-size: 14px;
    //font-weight: 500;
    line-height: 16px;
    //letter-spacing: 0.5px;
    color: #191C1F;

    @include ellipsisMultiLines(2);
    white-space: break-spaces;

    margin-top: 10px;
  }
}
