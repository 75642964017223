@import "@/styles/index.scss";

.notFound {
    flex: 1;
    font-family: var(--font-inter);
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    margin: 40px 0;
    color: #828B94;

    img {
        max-width: 256px;
        height: 256px;
        margin-bottom: 8px;
    }
}