@import "@/styles/index.scss";

.HeaderPageCategory {
    .categoryNameWrapper {
        display: flex;
        align-items: center;

        &.searchPage {
            margin-top: 0px;
            align-items: flex-end;
        }

        &.mobileSearchPage {
            @include mobileParameter(margin-top, 0, true);
        }

        justify-content: space-between;
        @include mobileParameter(justify-content, center);
        @include mediaParameter(margin-top, 8px, 10px, 10px, 22px);
        margin-bottom: 20px;
        @include mobileParameter(margin-bottom, 26px);

        .wrapTitle {
            display: flex;
            align-items: center;
        }

        .title {}

        .arrow {
            filter: brightness(0);
            margin-right: 4px;
        }

        .wrapSearch {
            .title {
                font-family: var(--font-inter);
                font-weight: 700;
                font-size: 32px;
                line-height: 39px;
                letter-spacing: 0.25px;
                color: #828B94;

                .value {
                    color: #191C1F;
                }
            }
        }

        .found {
            margin-top: 8px;
            @include mobileParameter(margin-top, 0px);
            // margin-bottom: 20px;
            @include labelFontSize;
        }
    }

    .wrapButtons {
        display: flex;
        gap: 12px;

        &>* {
            flex: 1;
        }

        &.mobileMargin {
            margin-bottom: 12px;
        }
    }

    .sortby {
        margin-left: 1.5rem;
        height: 32px;
        @include setProperty(font-size, --font-size-sm, true);
        align-self: flex-start;

        &.pageSearch {
            margin-top: 0;
        }
    }

    .resetPanel {
        margin-bottom: 20px;
    }
}

.modal {
    padding: 0 16px 12px 16px;
    border-radius: 8px 8px 0 0;
}

.modalHeader {

    [name="close2"] {
        top: 14px;
        right: 12px;
    }
}
