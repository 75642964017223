@import "@/styles/index.scss";

.Link,
.LinkServer {
  @include setProperty(color, --link-color);

  @include hovered();

  &>* {
    pointer-events: none;
  }

  &.active {
    @include inactive();
  }
}
