@import "@/styles/index.scss";

.toggleSwitch {
  @include setProperty(background-color, --switch-color-active);
  width: 32px;
  height: 16px;
  border-radius: 16px;
  padding: 2px;
  transition: background-color 0.5s;
  cursor: pointer;
  margin: 10px 0;

  .toggle {
    height: 22px;
    width: 22px;
    border-radius: 11px;
    @include setProperty(background-color, --white-background-color);
    margin-top: -5px;
    margin-left: 11px;
    transition: margin-left 0.5s;

    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);

    &.disabled {
      
    }
  }

  &.off {
    @include setProperty(background-color, --switch-color);

    .toggle {
      margin-left: -5px;
    }
  }

  &.disabled {
    filter: grayscale(1) opacity(0.7);
  }
}
