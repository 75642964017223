.PlaceholderBlock {
    --start-color: #f2f4f7;
    --end-color: #e6eaed;
    width: 100%;
    height: 548px;
    background: linear-gradient(90deg,
            var(--start-color) 0%,
            var(--end-color) 50%,
            var(--start-color) 100%);
    background-size: 200% 100%;
    animation: swing 1s linear infinite;

    mask-image: url("/assets/filter-placeholder-clip.svg");
    mask-repeat: no-repeat;
    mask-size: cover;

}

@keyframes swing {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}