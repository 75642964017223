@import "@/styles/index.scss";

.FilterList {

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        justify-content: space-between;

        .labelWrapper {
            display: flex;
            align-items: center;
        }

        .label {
            font-weight: 700;
            @include setProperty(color, --color-black);
            @include labelFontSize;
            overflow-wrap: anywhere;
        }

        .quantity {
            margin-left: 0.5rem;
            color: #828B94;
            margin-right: 14px;
        }

        .linkArrow {
            cursor: pointer;
            path {
                fill: #828B94;
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 1rem;

        .quantity {
            color: #828B94;
        }
    }

    .show {
        margin-top: 16px;
        @include setProperty(color, --color-primary);
        @include hovered();
        text-decoration: underline;
    }
}

.customLabel {
    max-width: 200px;
    @include ellipsisMultiLines(3);
    white-space: break-spaces;
    // TODO target fixes
    display: block;
}
