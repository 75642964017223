@import "@/styles/index.scss";

.ButtonShowMore {
    text-align: center;

    &.footerMargin {
        margin-top: 24px;
    }

    .button {
        height: 36px;
        padding: 0 20px;
        display: flex;
        margin: 0 auto;
        border-radius: 8px;
        box-shadow: none;
        @include labelFontSize;
        @include mobileParameter(padding-left, 10px);
        @include mobileParameter(padding-right, 10px);

        &.loading {
            //@include setProperty(color, --button-color-secondary, true);
            //background: none !important;
        }

        &.variantText {
            border: none;
            display: inline-block;
            box-shadow: none;
            padding: 0;
        }

        .loader {

        }
    }
}

