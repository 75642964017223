@import "@/styles/index.scss";

.labelColor {
    display: flex;
    align-items: center;

    .color {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin: -1px 0;
        border: 1px solid #E6EAED;
    }

    .label {
        margin-left: 0.5rem;
        max-width: 200px;
        @include ellipsisMultiLines(1);
        // TODO target fixes
        display: block;
    }
}

.colorsWrapper {
    // div:nth-child(2) {
    //     max-height: 150px !important;
    // }

}

.div {
    margin: 12px 0;
    border-top: 1px solid #E6EAED
}

.div2 {
    margin: 20px 0 12px;
}

.div3 {
    margin: 14px 0 10px;
}

.buttonApply {
    width: 100%;
    margin-top: 32px;
    height: 48px;
    border-radius: 4px;
}

.PanelStoreResetFilters, .PanelCategoryResetFilters {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.priceRange {
    margin-top: 12px;
}
