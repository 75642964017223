@import "@/styles/index.scss";

.LinkCategory {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 24px;
    @include hovered();

    &.active {
        background: #F0F2F5;
    }

    .icon {
        height: 24px;
        width: 24px;
        min-width: 24px;
    }

    .name {
        color: #191C1F;
        @include ellipsisMultiLines(1);
        display: block;
        white-space: nowrap;
        max-width: 80%;
        @include labelFontSize;
        line-height: 1;
    }
}