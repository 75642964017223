@import "@/styles/index.scss";

.Button {
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 0.02857em;
  white-space: pre;

  &>* {
    pointer-events: none;
  }

  height: 44px;

  @include setProperty(border, --button-border);
  @include setProperty(padding, --button-padding);
  @include setProperty(border-radius, --button-border-radius);
  @include setProperty(font-weight, --button-font-weight);
  @include setProperty(box-shadow, --button-shadow);

  transition: color 0.3s, background-color 0.3s, border-color 0.3s;

  outline: none !important;

  &:hover {
    cursor: pointer;
  }

  &.variantContained {

    @mixin colorsContained($prefix) {
      @include setProperty(--background-color, unquote("--button-color-#{$prefix}"));
      @include setProperty(--border-color, unquote("--button-color-#{$prefix}"));
      @include setProperty(--color, unquote("--button-text-color-#{$prefix}"));

      &:hover {
        @include setProperty(--background-color, unquote("--button-color-#{$prefix}-hover"));
        @include setProperty(--border-color, unquote("--button-color-#{$prefix}-hover"));
      }

      &:active {
        @include setProperty(--background-color, unquote("--button-color-#{$prefix}-pressed"));
      }
    }

    &.colorPrimary {
      @include colorsContained("primary");
    }

    &.colorSecondary {
      @include colorsContained("secondary");
    }

    &.colorWarning {
      @include colorsContained("warning");
    }

    &.colorSuccess {
      @include colorsContained("success");
    }

    color: var(--color);
    border-color: var(--border-color);
    background-color: var(--background-color);

    &:disabled {
      @include setProperty(background-color, --button-disabled-color, true);
      @include setProperty(border-color, --button-disabled-color, true);
      @include setProperty(color, --button-disabled-text-color, true);
    }
  }


  &.variantOutlined {
    border-width: 1px;
    --background-color: white;

    @mixin colorsOutlined($prefix) {
      @include setProperty(--border-color, unquote("--button-color-#{$prefix}"));
      @include setProperty(--color, unquote("--button-outline-text-color-#{$prefix}"));

      &:hover {
        @include setProperty(--background-color, unquote("--button-outline-#{$prefix}-hover"));
      }

      &:active {
        @include setProperty(--background-color, unquote("--button-outline-#{$prefix}-pressed"));
      }
    }

    &.colorPrimary {
      @include colorsOutlined("primary");
    }

    &.colorSecondary {
      @include colorsOutlined("secondary");
    }

    &.colorWarning {
      @include colorsOutlined("warning");
    }

    &.colorSuccess {
      @include colorsOutlined("success");
    }

    color: var(--color);
    border-color: var(--border-color);
    background-color: var(--background-color);

    &:disabled {
      @include setProperty(background-color, --button-outline-disabled-color, true);
      @include setProperty(border-color, --button-outline-disabled-border-color, true);
      @include setProperty(color, --button-outline-disabled-text-color, true);
    }
  }


  &.variantText {
    border-color: transparent;
    display: inline-block;
    box-shadow: none;
    padding: 0;
    background: none;
    @include hovered(false, true);

    @mixin colorsText($prefix) {
      @include setProperty(--color, unquote("--button-text-#{$prefix}"));
    }

    &.colorPrimary {
      @include colorsText("primary");
    }

    &.colorSecondary {
      @include colorsText("secondary");
    }

    &.colorSuccess {
      @include colorsText("success");
    }

    &.colorWarning {
      @include colorsText("warning");
    }

    color: var(--color);

    &:disabled {
      @include setProperty(color, --button-disabled-text-color, true);
    }
  }

  &.sizeSmall {
    padding: 3px 9px;
    font-size: 0.85rem;
  }

  &.sizeLarge {
    padding: 7px 21px;
    font-size: 1.15rem;
  }

  &:disabled {
    pointer-events: none;
  }

  .loader {
    margin-left: 10px;
  }

  .leftIcon {
    margin-right: 10px;
  }
}
