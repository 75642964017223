@import "@/styles/index.scss";

.FilterToggle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 700;
    @include labelFontSize;

    //margin: -4px 0;
}
