@import "@/styles/index.scss";

.input {
  &.showCleanButton {
    padding-right: 46px;
  }

  &.withIcon {
    &.iconLeft {
      padding-left: 46px;
    }

    &.iconRight {
      padding-right: 46px;
    }
  }

  &.withCharsCount {
    padding-right: 46px;
  }
}

.prefix {
  position: absolute;
}

.icon {
  display: flex;
  align-items: center;
  color: gray;
  width: 24px;
  position: absolute;
  height: 100%;
  top: 0;
  line-height: 1;

  &.iconLeft {
    left: 12px;
  }

  &.iconRight {
    right: 12px;
  }
}

.clean {
  position: absolute;
  height: 100%;
  top: 0px;
  right: 12px;
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
  }
}

.characters {
  @include setProperty(font-size, --font-size-sm);
  line-height: 16px;
  letter-spacing: 0.5px;
}

.loader {
  position: absolute;
  right: 12px;
  top: 0;
  height: 100%;
}
