@import "@/styles/index.scss";

.MobileStoreCategoryFilters {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 12px;
    margin-top: 12px;
    margin-bottom: 14px;

    span {
        display: inline-block;
        white-space: nowrap;
        max-width: 80vw;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.CategoryList {
    min-height: 80vh;

    .loader {
        display: flex;
        min-height: 80vh;
        justify-content: center;
    }

    .header {
        height: 48px;
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 14px;
        font-weight: 700;
        @include setProperty(color, --color-black);

        div {
            display: flex;
            margin-top: 2px;

            span {
                display: inline-block;
                white-space: nowrap;
                max-width: 60vw;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .current {
        padding: 12px 0px;
        font-size: 15px;
        @include setProperty(color, --color-primary);
    }

    .list {
        font-size: 1rem;
        @include setProperty(color, --color-black);

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0px;
            border-top: 1px solid;
            @include setProperty(border-color, --color-light-grey);

            svg {
                @include setProperty(color, --color-secondary);
            }
        }
    }
}

.modal {
    padding: 0 16px 12px 16px;
    border-radius: 8px 8px 0 0;
}

.modalHeader {

    [name="close2"] {
        top: 14px;
        right: 12px;
    }
}