@import "@/styles/index.scss";

.FilterPriceRange {
    width: 100%;

    --height: 44px;

    .label {
        font-weight: 700;
        margin-bottom: 12px;
        @include labelFontSize;
        @include setProperty(color, --color-black);
    }

    .body {
        // height: 44px;
        // max-height: 44px;
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 12px;

        &>* {
            display: inline-flex !important;
            align-self: center !important;
        }

        .input {
            height: unset;
            min-height: unset;
            max-height: unset;
            flex: 1;
            border: 1px solid #828B94;
            overflow: hidden;
            //@include setProperty(border-radius, --button-border-radius);

            input {
                padding-left: 22px;
                @include labelFontSize;
                line-height: 1;
                height: calc(var(--height) - 2px);
                min-height: unset;
                max-height: unset;
                outline: 0;
            }

            &.error {
                @include setProperty(color, --color-error);
            }
        }

        .icon {
            width: unset;

            &.notMacOS {
                // top: 2px; // TODO fast fix
            }
        }

        .delimiter {
            margin: 0 4px;
        }
    }

    .button {
        width: 56px;
        height: var(--height);
        @include mediaParameterMd(width, 48px);
        padding: 12px 16px;
        margin-left: 8px;
        @include labelFontSize;
        @include setProperty(border-radius, --input-border-radius);
    }

    .textError {
        font-size: 0.7rem;
        @include setProperty(color, --color-error);
        margin-top: 0.5rem;
    }

    .range {
        margin-top: 12px;
    }
}
