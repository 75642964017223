@import "@/styles/index.scss";

.checkbox {
  @include setProperty(--black, --checkbox-black-color);
  @include setProperty(--white, --checkbox-white-color);
  @include setProperty(--dark, --checkbox-dark-color);
  @include setProperty(--border-radius, --checkbox-border-radius);

  display: block;
  position: relative;
  box-sizing: border-box;
  &>*{
    box-sizing: border-box;
  }

  &_custom {
    display: flex;
    align-items: center;
    cursor: pointer;

    &>input {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:not(:disabled):not(:checked)+.span:hover::before {
        // border-color: var(--black);
      }

      &:not(:disabled):active+.span::before {
        @include setProperty(border-color, --switch-color-disabled);
      }

      &:checked+.span::before {
        border-color: var(--black);
        background-color: var(--black);
        // @include setProperty(background-color, --black-background-color);
        // background-image: url("/@/assets/icons/check.svg");
        background-size: auto;
      }

      &:disabled+.span::before {
        @include setProperty(border-color, --opacity-background-color);
        @include setProperty(background-color, --opacity-background-color);
      }

      &.compact {
        &:checked+.span::before {
          background-size: contain;
        }
      }
    }

    &>.span {
      flex: 1;
      display: inline-flex;
      align-items: center;
      user-select: none;
      white-space: nowrap;

      .label {
        margin-left: 8px;
        padding-top: 2px;
      }

      &::before {
        content: "";
        box-sizing: border-box;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border-radius: var(--border-radius);
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid;
        border-color: var(--black);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
        background-color: var(--white);

        transition: all 0.2s;
      }

      &.compact {
        &::before {
          width: 16px;
          height: 16px;
          border-radius: 4px;
          border-radius: var(--border-radius);
          @include setProperty(border-color, --input-border);
          background-color: var(--white);
        }
      }

      &.circle {
        &::before {
          border-radius: 20px;
        }
      }
    }
  }

  &.dark {
    &_custom {
      &>input {
        &:not(:disabled):not(:checked)+.span:hover::before {
          // border-color: var(--dark);
        }

        &:checked+.span::before {
          border-color: var(--dark);
          background-color: var(--dark);
        }
      }

      &>.span {
        &::before {
          border-color: var(--dark);
        }
      }
    }
  }

  .icon {
    position: absolute;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-left: 2px;
    color: var(--white);

    transition: all 0.2s;

    &.checked {
      color: var(--black);
    }

    &.circle {
      width: 15px;
      height: 16px;
    }

    &.compact {
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-left: 2px;
    }

    &.circle.compact {
      height: 10px;
      width: 11px;
    }
  }
}
